//TOP NAVIGATION BAR
.awsui_padding-box_k5dlb_7kx3l_124 {
  height: calc(
    var(--space-xxxl-bo9z7f, 40px) + var(--space-scaled-m-pv0fmt, 16px) + 4px
  ) !important;
}

.profile-picture {
  object-fit: cover;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-top: -3px;
}
.profile-name {
  padding-top: 5px;
  font-size: 16px !important;
}

.app-header {
  height: 60px !important;
  position: sticky;
  top: 0;
  z-index: 1002;
  font-size: 14px;
  line-height: 22px;
  color: rgb(213, 219, 219);
  font-weight: 400;
  background: rgb(35, 47, 62);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.header-text {
  color: rgb(213, 219, 219) !important;
  font-size: 18px;
  line-height: 22px !important;
  letter-spacing: normal;
  font-weight: 700;
  -webkit-font-smoothing: auto;
  padding-bottom: 4px !important;
}
.claims-search-bar {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-basis: 100%;
}
.input-filter {
  max-width: 450px;
  flex-basis: 100%;
}
.title-and-search-bar {
  display: flex;
  align-content: flex-start;
  flex-basis: 100%;
}
.page-title {
  flex-shrink: 0;
  padding-right: 20px;
}
.actions {
  display: flex;
  align-content: flex-end;
  flex-shrink: 0;
  gap: 10px;
  padding-right: 12px;
  padding-top: 2px;
}
.search-by-button {
  flex-shrink: 0;
  padding-right: 20px;
}

.search-icon {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding: 6px 14px 5px 0;
  flex-basis: 100%;
  border-right-style: solid;
  border-right-color: rgb(213, 219, 219);
  border-width: 1px;
}
.no-border {
  border-width: 0px !important;
}
.transparent-button {
  background-color: transparent;
  border-width: 0;
  border-color: transparent;
  cursor: pointer;
}

.close-search-button {
  flex-shrink: 0;
  align-self: center;
  padding-right: 20px;
  justify-content: flex-end;
}

.small-search-by-button {
  flex-shrink: 0;
  align-self: center;
  padding-right: 20px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.search-bar-alert {
  padding-top: 15px;
}
