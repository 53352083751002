@media only screen and (max-width: 768px) {
  #progressBar {
    display: none;
  }
  #wizard-header-progress-mobile {
    display: flex;
    flex-basis: 100%;
    flex-direction: column-reverse;
  }
  #wizard-header-buttons {
    flex-wrap: wrap;
    margin-top: 20px;
    display: flex;
    align-content: flex-end;
    margin-left: auto;
  }
}
@media only screen and (max-width: 1000px) {
  .profile-name {
    display: none;
  }
}
.App {
  margin-top: 20px;
}
.form-page-error {
  font-size: 1rem;
}
.text-underline {
  text-decoration: underline;
}
