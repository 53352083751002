@import "@amzn/awsui-design-tokens/polaris.scss";
@import "./styles/Forms.scss";
@import "./styles/Wizard";
@import "./styles/TopNavigationBar";
@import "./styles/Footer";
@import "./styles/ReviewPage";
html {
  scroll-behavior: smooth;
}
.awsui_content_1fj9k_avnjw_21 {
  max-width: 1000px !important;
}

.unknown-error {
  word-break: break-word !important;
  color: rgb(209, 50, 18);
}

.modal-text {
  font-size: 0.9rem;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.side-menu-wrapper {
  z-index: 50;
}
