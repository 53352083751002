@media (max-width: 565px) {
  wbr {
    visibility: hidden;
    display: none;
  }
}

form {
  //Use margin: auto to center the form when AppLayout type is set to "dashboard"
  // max-width: 800px;
  margin-bottom: 40px;
}

.info-icon-popover {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
