.progressBar {
  display: inline-block;
  font-family: var(
    --font-family-base-ua1f64,
    "Amazon Ember",
    "Helvetica Neue",
    Roboto,
    Arial,
    sans-serif
  );
  width: 200px;
  min-width: 200px;
  margin-right: 80px;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
  align-self: flex-start;
}

.progressOuter {
  border-bottom: var(--border-divider-list-width-hacikr, 1px) solid
    var(--color-border-layout-ifju5o, #d5dbdb);
  list-style-type: none;
  padding-bottom: 16px;
}

.progressStep {
  color: rgb(95, 113, 111);
  position: relative;
  bottom: 0;
  left: 0;
}
.progressText {
  font-size: 14px;
  font-stretch: 100%;
  font-weight: 700;
  color: var(--color-text-body-default-7m2b3r, #16191f);
}

.progress-step-spacer {
  margin-top: 16px;
}

.currentStep {
  color: rgb(22, 25, 21);
}

.visitedStep {
  color: rgb(0, 15, 187);
  text-decoration: underline;
  cursor: pointer;
}

.nonVisitedStep {
  color: rgb(95, 113, 111);
  cursor: default;
}

.wizardOuter {
  display: flex;
}

.wizardHeader {
  font-size: 28px;
  font-stretch: 100%;
  font-weight: 400;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-top: 0;
  margin-bottom: 0;
  outline: none;
}
.wizard-header-wrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

.wizard-header-description {
  font-size: 14px;
  font-stretch: 100%;
  color: rgb(95, 113, 111);
  padding-top: 4px;
}

.wizard-footer-outer {
  margin-top: 20px;
  display: flex;
  align-content: flex-end;
  flex-direction: row-reverse;
}

.wizard-header-progress-mobile {
  display: none;
}

.wizard-content {
  display: block;
  max-width: 1000px !important;
  min-width: 280px;
  width: 760px;
}
.wizard-header-buttons {
  display: none;
}
